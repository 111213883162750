/* .App {
  text-align: center;
} */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.box {
  transition: box-shadow 0.3s ease-in-out;
  background-color: #f5f5f5;
}

.box:hover {
  box-shadow: 0 6px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  transition: box-shadow 0.3s ease-in-out;
}

input,
textarea,
label,
td {
  text-align: right;
  direction: rtl;
}

input,
select,
textarea {
  color: #292f33 !important;
  font-size: 16px !important;
  font-weight: bold;
}

label {
  /* text-align: left; */
  float: right;
}

.has-feedback .form-control {
  padding-right: 5px;
}

h1,
h2,
h3 {
  text-align: center;
}

input[type='checkbox'] {
  /* margin-top: 25px; */
  /* text-align: right; */
  height: 15px;
  width: 15px;
  cursor: pointer;
}

/* input[type='checkbox']:hover {
  background-color: blue;
} */

p.title {
  margin-top: 25px;
  text-align: right;
}

div > div.form-control > div > div {
  position: relative;
}

div > label {
  z-index: 2;
  position: relative;
}

label > span {
  margin: 20px;
}

.choices {
  padding-bottom: 12px;
  padding-top: 25px;
}

select {
  background-color: green;
  z-index: 5;
  padding: 100px;
}

button[type='submit'] {
  background-color: #16a085;
  border-color: #16a085;
  color: white;
}

button[type='submit']:hover {
  background-color: #19b394;
  border-color: #19b394;
  color: white;
}

.formio-component-datetime {
  display: flex;
  flex-direction: column;
}

.red {
  color: red;
  font-weight: bold;
}

.close {
  background-color: gray;
  /* z-index: 5; */
  border-radius: 50%;
  padding: 10px;
  color: red;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 20px;
  opacity: 1;
}

.popup-content {
  width: 840px !important;
  /* background-color: red !important; */
}

/* Bob source: Hover.css */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* sponsorPrint */

.printHeader {
  text-align: center;
  font-size: 25px;
  color: black;
  font-weight: bolder;
  text-shadow: 2px 0px 0px grey;

  /* -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out; */
  -webkit-transition: font-size 0.4s ease-in-out;
  transition: font-size 0.4s ease-in-out;
}

#div1 {
  align-content: center;
  width: 98%;
  border-radius: 5px;
  padding: auto;
  border: 2px solid grey;
  background-color: white;
  color: black;
  margin: auto;
}

.myTable {
  align-content: right;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
  margin: 3px;
}

th {
  border: 1px solid darkgray;
  text-align: center;
  background-color: rgb(235, 229, 229);
  font-weight: bolder;
}

td {
  border: 1px solid darkgray;
  text-align: center;
}

tr:nth-child(even) {
  background-color: rgb(235, 229, 229);
}
