body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Tajawal', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .App {
  text-align: center;
} */

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.box {
  -webkit-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  background-color: #f5f5f5;
}

.box:hover {
  box-shadow: 0 6px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  -webkit-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
}

input,
textarea,
label,
td {
  text-align: right;
  direction: rtl;
}

input,
select,
textarea {
  color: #292f33 !important;
  font-size: 16px !important;
  font-weight: bold;
}

label {
  /* text-align: left; */
  float: right;
}

.has-feedback .form-control {
  padding-right: 5px;
}

h1,
h2,
h3 {
  text-align: center;
}

input[type='checkbox'] {
  /* margin-top: 25px; */
  /* text-align: right; */
  height: 15px;
  width: 15px;
  cursor: pointer;
}

/* input[type='checkbox']:hover {
  background-color: blue;
} */

p.title {
  margin-top: 25px;
  text-align: right;
}

div > div.form-control > div > div {
  position: relative;
}

div > label {
  z-index: 2;
  position: relative;
}

label > span {
  margin: 20px;
}

.choices {
  padding-bottom: 12px;
  padding-top: 25px;
}

select {
  background-color: green;
  z-index: 5;
  padding: 100px;
}

button[type='submit'] {
  background-color: #16a085;
  border-color: #16a085;
  color: white;
}

button[type='submit']:hover {
  background-color: #19b394;
  border-color: #19b394;
  color: white;
}

.formio-component-datetime {
  display: flex;
  flex-direction: column;
}

.red {
  color: red;
  font-weight: bold;
}

.close {
  background-color: gray;
  /* z-index: 5; */
  border-radius: 50%;
  padding: 10px;
  color: red;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 20px;
  opacity: 1;
}

.popup-content {
  width: 840px !important;
  /* background-color: red !important; */
}

/* Bob source: Hover.css */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* sponsorPrint */

.printHeader {
  text-align: center;
  font-size: 25px;
  color: black;
  font-weight: bolder;
  text-shadow: 2px 0px 0px grey;

  /* -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out; */
  -webkit-transition: font-size 0.4s ease-in-out;
  transition: font-size 0.4s ease-in-out;
}

#div1 {
  align-content: center;
  width: 98%;
  border-radius: 5px;
  padding: auto;
  border: 2px solid grey;
  background-color: white;
  color: black;
  margin: auto;
}

.myTable {
  align-content: right;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
  margin: 3px;
}

th {
  border: 1px solid darkgray;
  text-align: center;
  background-color: rgb(235, 229, 229);
  font-weight: bolder;
}

td {
  border: 1px solid darkgray;
  text-align: center;
}

tr:nth-child(even) {
  background-color: rgb(235, 229, 229);
}

.login-page-container {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: #50c9ac;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%233d9983' fill-opacity='0.32' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}

.login-page-form-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 9em;
}

.pad {
  padding: 25px;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.submit-button {
  width: 100%;
  color: white;
  cursor: pointer;
}

.login-page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red; */
  color: black;
  text-align: center;
  padding-bottom: 1rem;
}

/* To hide number controls from number inputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

